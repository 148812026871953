/* src/pages/HomePage.css */

.clickable-cell {
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s;
  }
  
  .clickable-cell:hover {
    background-color: #e6f7ff;
    border: 1px solid #1890ff;
  }
  
  .clickable-cell:active {
    background-color: #bae7ff;
  }
  